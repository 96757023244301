import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { changeLang } from '../../translate/i18n';
import { Link } from 'react-router-dom';

const Header = () => {
	const { t } = useTranslation();
	const [isLanguageDropdownVisible, setLanguageDropdownVisible] =
		useState(false);
	const [burgerActive, setBurgerActive] = useState(false);
	const dropdownRef = useRef(null);

	const navLinks = [
		{ text: t('lang1'), href: '#about' },
		{ text: t('lang2'), href: '#trade' },
		{ text: t('lang3'), href: '#contacts' },
	];
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setLanguageDropdownVisible(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dropdownRef]);

	return (
		<header className={styles.header}>
			<div className='container'>
				<div className={styles.headerBlock}>
					<Link href='/' className={styles.logoBlock}>
						<svg
							className={styles.logo}
							width='50'
							height='30'
							viewBox='0 0 40 20'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M19.9995 0C19.1444 0 18.3147 0.11547 17.5272 0.343449C14.2506 1.26129 11.671 3.96743 10.7961 7.40489C10.5788 8.23094 10.4688 9.10141 10.4688 9.99852C10.4688 13.6965 12.3851 16.9297 15.2299 18.6558C14.4115 17.1843 13.9458 15.473 13.9458 13.6491C13.9458 13.2879 13.9627 12.9297 14.0022 12.5803C14.4707 7.91414 18.007 4.20429 22.4549 3.71281C22.7879 3.67135 23.1294 3.65359 23.4738 3.65359C25.2123 3.65359 26.8435 4.14212 28.2462 5.00074C26.6065 2.01036 23.5246 0 19.9995 0Z'
								fill='#045599'
							/>
							<path
								d='M29.2064 7.40479C28.4529 10.3656 26.4321 12.7845 23.7933 13.9836C22.6305 14.5166 21.3492 14.8097 20.0002 14.8097C17.7282 14.8097 15.6398 13.9747 14.0057 12.5773C13.9662 12.9266 13.9492 13.2849 13.9492 13.6461C13.9492 15.4699 14.4149 17.1812 15.2334 18.6527C15.9474 19.0909 16.715 19.4285 17.5307 19.6565C18.3181 19.8844 19.1478 19.9999 20.003 19.9999C25.2693 19.9999 29.5366 15.5203 29.5366 9.99842C29.5338 9.10131 29.4237 8.23084 29.2064 7.40479Z'
								fill='#4478BB'
							/>
							<path
								d='M29.207 7.40513C28.4535 10.3659 26.4327 12.7849 23.7939 13.984C24.3047 12.7641 24.5842 11.414 24.5842 9.99877C24.5842 7.61535 23.7883 5.42734 22.459 3.71009C22.792 3.66864 23.1335 3.65088 23.4778 3.65088C25.2163 3.65088 26.8476 4.13941 28.2503 4.99803C28.6708 5.74414 28.9925 6.55243 29.207 7.40513Z'
								fill='#F48020'
							/>
							<path
								d='M7.89953 10.0016C7.89953 7.53822 8.57123 5.23771 9.72836 3.29248C4.97284 5.85059 1.80061 9.3058 0 11.63C1.13455 13.2762 3.11296 15.6981 6.20334 17.5812C8.29747 18.8572 10.4509 19.6034 12.5478 19.9971C9.71989 17.6729 7.89953 14.0608 7.89953 10.0016Z'
								fill='#02519E'
							/>
							<path
								d='M40.0009 8.37003C38.8664 6.72385 36.888 4.30194 33.7976 2.42185C31.7035 1.14576 29.5501 0.399642 27.4531 0.00585938C30.281 2.33006 32.1014 5.9422 32.1014 10.0014C32.1014 12.4648 31.4297 14.7653 30.2726 16.7105C35.0281 14.1524 38.2003 10.6942 40.0009 8.37003Z'
								fill='#F48020'
							/>
						</svg>
					</Link>
					<button
						onClick={() => setBurgerActive(!burgerActive)}
						className={`${burgerActive ? styles.active : ''} ${
							styles.headerBurger
						}`}
						id='burger'
					>
						<span></span>
					</button>
					<nav
						className={`${burgerActive ? styles.active : ''} ${styles.nav}`}
						id='nav'
					>
						<ul className={styles.navList}>
							{navLinks.map((link, index) => (
								<li className={styles.navItem} key={index}>
									<a className={styles.navLink} href={link.href}>
										{link.text}
									</a>
								</li>
							))}
							<li className={styles.navItem}>
								<a
									target='_blank'
									className={`${styles.navLink} ${styles.navLogin}`}
									href='https://ca.evde.kg/'
								>
									{t('lang5')}
								</a>
							</li>
							<li
								className={`${styles.navItem} ${styles.headerLanguageItem}`}
								ref={dropdownRef}
							>
								<button
									className={styles.headerLanguageButton}
									onClick={() =>
										setLanguageDropdownVisible(!isLanguageDropdownVisible)
									}
								>
									{t('lang6')}
									<svg
										width='12'
										height='8'
										viewBox='0 0 80 54'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M40 54L0 13.4976L13.3396 0L40 27.0048L66.6604 0L80 13.4976L40 54Z'
											fill='white'
										/>
									</svg>
								</button>
								{isLanguageDropdownVisible && (
									<ul className={styles.headerLanguageDropdown}>
										<li className={styles.headerLanguageOption}>
											<button onClick={() => changeLang('ru')}>Русский</button>
										</li>
										<li className={styles.headerLanguageOption}>
											<button onClick={() => changeLang('en')}>English</button>
										</li>
									</ul>
								)}
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</header>
	);
};

export default Header;
