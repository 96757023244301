import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles.scss';
import { useTranslation } from 'react-i18next';

const REFRESH_INTERVAL = 180000;

const Table = () => {
	const { t } = useTranslation();
	const [trades, setTrades] = useState({
		KGS: { price: '-', volume: '-' },
		RUB: { price: '-', volume: '-' },
		EUR: { price: '-', volume: '-' },
		USD: { price: '-', volume: '-' },
		CNY: { price: '-', volume: '-' },
	});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	// Функция для добавления 1.5% к цене
	const addPercentage = (price) => {
		const parsedPrice = parseFloat(price);
		if (isNaN(parsedPrice)) return '-';
		return (parsedPrice * 1.015).toFixed(4);
	};

	const fetchTrades = async () => {
		try {
			setLoading(true);
			const response = await axios.get('https://api2.evde.kg/api/rates'); // Локальный API-эндпоинт
			const [responseRUB] = await Promise.all([
				axios.get('https://garantex.org/api/v2/trades?market=usdtrub'),
			]);

			// Получаем и обновляем данные, добавляя 1.5% к ценам (кроме RUB)
			setTrades({
				KGS: {
					price: addPercentage(response.data.kgsRate || '-'),
					volume: '500000',
				},
				RUB: {
					price: addPercentage(responseRUB.data[0].price),
					volume: '1000000',
				},
				EUR: {
					price: addPercentage(response.data.eurRate || '-'),
					volume: '500000',
				},
				USD: { price: '1.015', volume: '500000' },
				CNY: {
					price: addPercentage(response.data.cnyRate || '-'),
					volume: '500000',
				},
			});
			setLoading(false);
		} catch (error) {
			console.error('Ошибка при получении данных:', error);
			setError('Произошла ошибка при загрузке данных');
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchTrades();
		const intervalId = setInterval(fetchTrades, REFRESH_INTERVAL);
		return () => clearInterval(intervalId);
	}, []);

	if (loading) return <div>Загрузка...</div>;
	if (error) return <div>{error}</div>;

	const tradeInfo = Object.entries(trades).map(([currency, data]) => ({
		currency,
		price: data.price,
		volume: data.volume,
	}));

	return (
		<div className='table' id='trade'>
			<div className='container'>
				<div className='table__wrapper'>
					<table className='table__table'>
						<thead>
							<tr>
								<th>{t('lang43')}</th>
								<th>{t('lang44')}</th>
								<th>{t('lang45')}</th>
							</tr>
						</thead>
						<tbody>
							{tradeInfo.map(({ currency, price, volume }) => (
								<tr key={currency}>
									<td>{currency}/USDT</td>
									<td>{price}</td>
									<td>{volume}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default Table;
