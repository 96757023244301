import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const About = () => {
	const { t } = useTranslation();
	return (
		<section className={styles.about} id='about'>
			<div className='container'>
				<h2 className={`${styles.about__title} title`}>{t('lang31')}</h2>
				<p className={styles.aboutText}>{t('lang32')}</p>
			</div>
		</section>
	);
};

export default About;
