import React, { useState } from 'react';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Home from './pages/Home';
import { Route, Routes } from 'react-router-dom';
import SignUpLegal from './components/sign-up/SignUpLegal';

function App() {
	return (
		<div className='wrap'>
			<Header />
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/sign-up' element={<SignUpLegal />} />
			</Routes>
			<Footer />
		</div>
	);
}

export default App;
