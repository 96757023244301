import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const Hero = () => {
	const { t } = useTranslation();
	return (
		<section className={styles.hero}>
			<div className='container'>
				<div className={styles.heroContent}>
					<h1 className={styles.heroTitle}>{t('lang7')}</h1>
					<p className={styles.heroDescription}>{t('lang8')}</p>
					<a href='#about' className={styles.heroButton}>
						{t('lang9')}
					</a>
				</div>
			</div>
		</section>
	);
};

export default Hero;
