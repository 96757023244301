import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Ru from './ru.json';
import Eng from './eng.json';

i18n.use(initReactI18next).init({
	resources: {
		ru: { translation: Ru },
		en: { translation: Eng },
	},
	detection: {
		order: ['localStorage', 'cookies'],
		caches: ['localStorage', 'cookies'],
	},
	fallbackLng: 'ru',
	interpolation: { escapeValue: false },
});

export const changeLang = (lang) => {
	i18n.changeLanguage(lang);
};

export default i18n;
