import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import privacy from '../../img/Privacy.pdf';

const SignUpLegal = () => {
	const [formData, setFormData] = useState({
		lastName: '',
		name: '',
		patronymic: '',
		phone: '',
		email: '',
		password: '',
		confirmPassword: '',
		role: '',
		companyName: '',
		inn: '',
		legalAddress: '',
		postalCode: '',
		bankName: '',
		bankAddress: '',
		currentAccount: '',
		bankId: '',
		swift: '',
		agreement: false,
	});

	const [errors, setErrors] = useState({});
	const [currentStep, setCurrentStep] = useState(1);
	const [showPdf, setShowPdf] = useState(false);
	const [attemptedNextStep, setAttemptedNextStep] = useState(false);

	const validateStep1 = () => {
		const newErrors = {};
		if (!formData.lastName) newErrors.lastName = 'Фамилия обязательна';
		if (!formData.name) newErrors.name = 'Имя обязательно';
		if (!formData.patronymic) newErrors.patronymic = 'Отчество обязательно';
		if (!formData.phone) newErrors.phone = 'Телефон обязателен';
		else if (!/^\+?[0-9]{10,14}$/.test(formData.phone))
			newErrors.phone = 'Неверный формат телефона';
		if (!formData.email) newErrors.email = 'Email обязателен';
		else if (!/\S+@\S+\.\S+/.test(formData.email))
			newErrors.email = 'Неверный формат email';
		if (!formData.password) newErrors.password = 'Пароль обязателен';
		else if (formData.password.length < 8) {
			newErrors.password = 'Пароль должен быть не менее 8 символов';
		} else if (/(.)\1{2,}/.test(formData.password)) {
			newErrors.password =
				'Пароль не должен содержать более двух одинаковых символов подряд';
		}
		if (!formData.confirmPassword)
			newErrors.confirmPassword = 'Подтверждение пароля обязательно';
		else if (formData.password !== formData.confirmPassword)
			newErrors.confirmPassword = 'Пароли не совпадают';
		if (!formData.role) newErrors.role = 'Выберите роль';

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const validateStep2 = () => {
		const newErrors = {};
		if (!formData.companyName)
			newErrors.companyName = 'Название компании обязательно';
		if (!formData.inn) {
			newErrors.inn = 'ИНН обязателен';
		} else if (!/^\d{6,}$/.test(formData.inn)) {
			newErrors.inn = 'ИНН должен содержать более 5 цифр';
		}
		if (!formData.legalAddress)
			newErrors.legalAddress = 'Юридический адрес обязателен';
		if (!formData.postalCode)
			newErrors.postalCode = 'Почтовый индекс обязателен';
		else if (!/^\d{4,}$/.test(formData.postalCode)) {
			newErrors.postalCode = 'ИНН должен содержать более 4 цифр';
		}
		if (!formData.bankName)
			newErrors.bankName = 'Наименование банка обязательно';
		if (!formData.bankAddress) newErrors.bankAddress = 'Адрес банка обязателен';
		if (!formData.currentAccount)
			newErrors.currentAccount = 'Расчетный счет обязателен';
		else if (!/^\d{8,}$/.test(formData.currentAccount))
			newErrors.currentAccount = 'Расчетный счет должен содержать более 8 цифр';
		if (!formData.bankId) newErrors.bankId = 'БИК обязателен';
		// else if (!/^\d{9}$/.test(formData.bankId))
		// 	newErrors.bankId = 'БИК должен содержать 9 цифр';
		if (!formData.swift) newErrors.swift = 'Код SWIFT обязателен';
		if (!formData.agreement)
			newErrors.agreement = 'Необходимо согласие с условиями';

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: type === 'checkbox' ? checked : value,
		}));
		if (attemptedNextStep) {
			if (currentStep === 1) {
				validateStep1();
			} else {
				validateStep2();
			}
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (validateStep2()) {
			// Здесь можно отправить данные на сервер
		}
	};

	const handleNextStep = () => {
		setAttemptedNextStep(true);
		if (validateStep1()) {
			setCurrentStep(2);
		}
	};

	const renderStep1 = () => (
		<div className={styles.signUp__step}>
			<h3 className={styles.signUp__stepTitle}>Шаг 1: Личные данные</h3>
			<div className={styles.signUp__content}>
				<div className={styles.signUp__formGroup}>
					<label className={styles.signUp__label} htmlFor='lastName'>
						Фамилия:
					</label>
					<input
						className={styles.signUp__input}
						type='text'
						id='lastName'
						name='lastName'
						value={formData.lastName}
						onChange={handleChange}
						required
					/>
					{errors.lastName && (
						<span className={styles.signUp__error}>{errors.lastName}</span>
					)}
				</div>
				<div className={styles.signUp__formGroup}>
					<label className={styles.signUp__label} htmlFor='name'>
						Имя:
					</label>
					<input
						className={styles.signUp__input}
						type='text'
						id='name'
						name='name'
						value={formData.name}
						onChange={handleChange}
						required
					/>
					{errors.name && (
						<span className={styles.signUp__error}>{errors.name}</span>
					)}
				</div>
				<div className={styles.signUp__formGroup}>
					<label className={styles.signUp__label} htmlFor='patronymic'>
						Отчество:
					</label>
					<input
						className={styles.signUp__input}
						type='text'
						id='patronymic'
						name='patronymic'
						value={formData.patronymic}
						onChange={handleChange}
						required
					/>
					{errors.patronymic && (
						<span className={styles.signUp__error}>{errors.patronymic}</span>
					)}
				</div>
				<div className={styles.signUp__formGroup}>
					<label className={styles.signUp__label} htmlFor='phone'>
						Контактный телефон:
					</label>
					<input
						className={styles.signUp__input}
						type='text'
						id='phone'
						name='phone'
						value={formData.phone}
						onChange={handleChange}
						required
					/>
					{errors.phone && (
						<span className={styles.signUp__error}>{errors.phone}</span>
					)}
				</div>
				<div className={styles.signUp__formGroup}>
					<label className={styles.signUp__label} htmlFor='email'>
						Email:
					</label>
					<input
						className={styles.signUp__input}
						type='email'
						id='email'
						name='email'
						value={formData.email}
						onChange={handleChange}
						required
					/>
					{errors.email && (
						<span className={styles.signUp__error}>{errors.email}</span>
					)}
				</div>
				<div className={styles.signUp__formGroup}>
					<label className={styles.signUp__label} htmlFor='password'>
						Пароль:
					</label>
					<input
						className={styles.signUp__input}
						type='password'
						id='password'
						name='password'
						value={formData.password}
						onChange={handleChange}
						required
					/>
					{errors.password && (
						<span className={styles.signUp__error}>{errors.password}</span>
					)}
				</div>
				<div className={styles.signUp__formGroup}>
					<label className={styles.signUp__label} htmlFor='confirmPassword'>
						Подтвердите пароль:
					</label>
					<input
						className={styles.signUp__input}
						type='password'
						id='confirmPassword'
						name='confirmPassword'
						value={formData.confirmPassword}
						onChange={handleChange}
						required
					/>
					{errors.confirmPassword && (
						<span className={styles.signUp__error}>
							{errors.confirmPassword}
						</span>
					)}
				</div>
				<div className={styles.signUp__formGroup}>
					<label className={styles.signUp__label} htmlFor='role'>
						Выберите роль:
					</label>
					<select
						className={styles.signUp__select}
						id='role'
						name='role'
						value={formData.role}
						onChange={handleChange}
						required
					>
						<option value=''>Выберите роль</option>
						<option value='broker'>Брокер</option>
						<option value='buyer'>Покупка - Продажа</option>
					</select>
					{errors.role && (
						<span className={styles.signUp__error}>{errors.role}</span>
					)}
				</div>
			</div>
			<button className={styles.signUp__nextButton} onClick={handleNextStep}>
				Далее
			</button>
		</div>
	);

	const renderStep2 = () => (
		<div className={styles.signUp__step}>
			<h3 className={styles.signUp__stepTitle}>
				Шаг 2: Данные юридического лица
			</h3>
			<div className={styles.signUp__content}>
				<div className={styles.signUp__formGroup}>
					<label className={styles.signUp__label} htmlFor='companyName'>
						Название компании:
					</label>
					<input
						className={styles.signUp__input}
						type='text'
						id='companyName'
						name='companyName'
						value={formData.companyName}
						onChange={handleChange}
						required
					/>
					{errors.companyName && (
						<span className={styles.signUp__error}>{errors.companyName}</span>
					)}
				</div>
				<div className={styles.signUp__formGroup}>
					<label className={styles.signUp__label} htmlFor='inn'>
						ИНН:
					</label>
					<input
						className={styles.signUp__input}
						type='number'
						id='inn'
						name='inn'
						value={formData.inn}
						onChange={handleChange}
						required
					/>
					{errors.inn && (
						<span className={styles.signUp__error}>{errors.inn}</span>
					)}
				</div>
				<div className={styles.signUp__formGroup}>
					<label className={styles.signUp__label} htmlFor='legalAddress'>
						Юридический адрес:
					</label>
					<input
						className={styles.signUp__input}
						type='text'
						id='legalAddress'
						name='legalAddress'
						value={formData.legalAddress}
						onChange={handleChange}
						required
					/>
					{errors.legalAddress && (
						<span className={styles.signUp__error}>{errors.legalAddress}</span>
					)}
				</div>
				<div className={styles.signUp__formGroup}>
					<label className={styles.signUp__label} htmlFor='postalCode'>
						Почтовый индекс
					</label>
					<input
						className={styles.signUp__input}
						type='number'
						id='postalCode'
						name='postalCode'
						value={formData.postalCode}
						onChange={handleChange}
						required
					/>
					{errors.postalCode && (
						<span className={styles.signUp__error}>{errors.postalCode}</span>
					)}
				</div>

				<div className={styles.signUp__formGroup}>
					<label className={styles.signUp__label} htmlFor='bankName'>
						Наименование банка
					</label>
					<input
						className={styles.signUp__input}
						type='bankName'
						id='bankName'
						name='bankName'
						value={formData.bankName}
						onChange={handleChange}
						required
					/>
					{errors.bankName && (
						<span className={styles.signUp__error}>{errors.bankName}</span>
					)}
				</div>
				<div className={styles.signUp__formGroup}>
					<label className={styles.signUp__label} htmlFor='bankAddress'>
						Адрес банка
					</label>
					<input
						className={styles.signUp__input}
						type='bankAddress'
						id='bankAddress'
						name='bankAddress'
						value={formData.bankAddress}
						onChange={handleChange}
						required
					/>
					{errors.bankAddress && (
						<span className={styles.signUp__error}>{errors.bankAddress}</span>
					)}
				</div>
				<div className={styles.signUp__formGroup}>
					<label className={styles.signUp__label} htmlFor='currentAccount'>
						Расчетный счет
					</label>
					<input
						className={styles.signUp__input}
						type='number'
						id='currentAccount'
						name='currentAccount'
						value={formData.currentAccount}
						onChange={handleChange}
						required
					/>
					{errors.lastName && (
						<span className={styles.signUp__error}>
							{errors.currentAccount}
						</span>
					)}
				</div>
				<div className={styles.signUp__formGroup}>
					<label className={styles.signUp__label} htmlFor='bankId'>
						БИК
					</label>
					<input
						className={styles.signUp__input}
						type='number'
						id='bankId'
						name='bankId'
						value={formData.bankId}
						onChange={handleChange}
						required
					/>
					{errors.bankId && (
						<span className={styles.signUp__error}>{errors.bankId}</span>
					)}
				</div>
				<div className={styles.signUp__formGroup}>
					<label className={styles.signUp__label} htmlFor='swift'>
						Код Swift
					</label>
					<input
						className={styles.signUp__input}
						type='text'
						id='swift'
						name='swift'
						value={formData.swift}
						onChange={handleChange}
						required
					/>
					{errors.swift && (
						<span className={styles.signUp__error}>{errors.swift}</span>
					)}
				</div>
			</div>

			<div className={styles.signUp__agreement}>
				<div className={styles.signUp__checkboxGroup}>
					<input
						type='checkbox'
						id='agreement'
						name='agreement'
						className={styles.signUp__checkbox}
						checked={formData.agreement}
						onChange={handleChange}
					/>
					<label htmlFor='agreement' className={styles.signUp__checkboxLabel}>
						Я согласен с условиями
						<button
							type='button'
							className={styles.signUp__pdfLink}
							onClick={() => setShowPdf(true)}
						>
							Пользовательского соглашения
						</button>
					</label>
				</div>
				{errors.agreement && (
					<span className={styles.signUp__error}>{errors.agreement}</span>
				)}
			</div>

			<div className={styles.signUp__navigation}>
				<button
					className={styles.signUp__prevButton}
					onClick={() => setCurrentStep(1)}
				>
					Назад
				</button>
				<button className={styles.signUp__button} type='submit'>
					Зарегистрироваться
				</button>
			</div>
		</div>
	);

	return (
		<section className={styles.signUp}>
			<div className='container'>
				<h2 className={styles.signUp__title}>Регистрация юридического лица</h2>
				<form className={styles.signUp__form} onSubmit={handleSubmit}>
					{currentStep === 1 ? renderStep1() : renderStep2()}
				</form>
			</div>
			{showPdf && (
				<div className={styles.signUp__pdfOverlay}>
					<div className={styles.signUp__pdfContainer}>
						<button
							className={styles.signUp__pdfClose}
							onClick={() => setShowPdf(false)}
						>
							Закрыть
						</button>
						<iframe
							src={privacy}
							className={styles.signUp__pdfFrame}
							title='Пользовательское соглашение'
						/>
					</div>
				</div>
			)}
		</section>
	);
};

export default SignUpLegal;
