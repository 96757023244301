import React, { useState } from 'react';
import Hero from '../components/hero/Hero';
import CommodityExchange from '../components/commodity-ex/CommodityExchange';
import About from '../components/about/About';
import Services from '../components/services/Services';
import Table from '../components/trade-table/Table';
import { useTranslation } from 'react-i18next';

const Home = () => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState('evde');
	const [showCommodityExchange, setShowCommodityExchange] = useState(true);

	const tabs = [
		{ name: 'evde', label: 'EVDE' },
		{ name: 'belarus', label: 'lang10' },
	];

	const handleTabClick = (tabName) => {
		setActiveTab(tabName);
		setShowCommodityExchange(true);
	};

	const handleTradeClick = () => {
		setActiveTab('trade');
		setShowCommodityExchange(false);
	};

	return (
		<>
			<Hero />
			<div className='tabs'>
				{tabs.map((tab) => (
					<button
						key={tab.name}
						onClick={() => handleTabClick(tab.name)}
						className={`${activeTab === tab.name ? 'active' : ''} tabs_btn`}
					>
						{t(tab.label)}
					</button>
				))}
				<button
					className={`${activeTab === 'trade' ? 'active' : ''} tabs_btn`}
					onClick={handleTradeClick}
				>
					{t('lang11')}
				</button>
			</div>
			{showCommodityExchange ? (
				<CommodityExchange activeTab={activeTab} />
			) : (
				<Table />
			)}
			<About />
			<Services />
		</>
	);
};

export default Home;
