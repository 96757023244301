import React from 'react';
import pastCommodityData from '../../data/pastCommodityData.json';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const PastCommodityTable = ({ currentTab, activeTab }) => {
	const { t } = useTranslation();
	if (
		!pastCommodityData ||
		!pastCommodityData[activeTab] ||
		!pastCommodityData[activeTab][currentTab]
	) {
		return <p>Загрузка данных...</p>;
	}

	const data = pastCommodityData[activeTab][currentTab];

	return (
		<table className={styles.commodityExchangeTable}>
			<thead>
				<tr>
					<th>{t('lang16')}</th>
					<th>{t('lang17')}</th>
					<th>{t('lang18')}</th>
				</tr>
			</thead>
			<tbody>
				{data.map((item, index) => (
					<tr key={index}>
						<td>{t(item.name)}</td>
						<td
							className={`${styles.commodityExchangePriceChange} ${
								item.change >= 0
									? styles.commodityExchangePriceChangeUp
									: styles.commodityExchangePriceChangeDown
							}`}
						>
							{item.change > 0 ? '+' : ''}
							{item.change.toFixed(2)}%
						</td>
						<td>{item.price.toFixed(2)}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default PastCommodityTable;
